const listCountries = [
  {
    code: 'US',
    country: 'USA',
    name: 'United State'
  },
  {
    code: 'GB',
    country: 'UK',
    name: 'United Kingdom'
  },
  {
    code: 'UK',
    country: 'UK',
    name: 'United Kingdom'
  },
  {
    code: 'AE',
    country: 'UAE',
    name: 'United Arab Emirates'
  },
  {
    code: 'IN',
    country: 'India',
    name: 'India'
  },
];

function ProductCountries(thisCountry) {
  const findProductCountry = listCountries?.find((c) => c.code == thisCountry)
  return findProductCountry ? findProductCountry?.country : thisCountry
}

export default ProductCountries;