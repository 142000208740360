import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useSelector, useDispatch } from "react-redux";
import { useGetUserDataQuery } from '../../services/login';
import { useFetchQuery } from '../../services/cart';
import { useGetShippingAddressesQuery } from '../../services/login';
import { useCreateCheckoutMutation } from '../../services/checkout';
import Host from '../../Components/Host';
import { useTranslation } from 'react-i18next'
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import CircularProgress from '@mui/material/CircularProgress';
import Loader from '../../Components/Loader';
import { Modal } from 'react-bootstrap'
import IconButton from '@mui/joy/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import FooterText from '../../Components/CheckoutComponent/FooterText';
import GuestShippingAddress from '../../Components/CheckoutComponent/GuestShippingAddress';
import ShippingAddress from '../../Components/CheckoutComponent/ShippingAddress';
import CartItems from '../../Components/CheckoutComponent/CartItems';
import CartIInformation from '../../Components/CheckoutComponent/CartIInformation';
import TapTabbyCheckout from '../../Components/CheckoutComponent/TapTabbyCheckout';
import PayByApplePay from '../../Components/CheckoutComponent/PayByApplePay';
import TapCardCheckout from '../../Components/CheckoutComponent/TapCardCheckout';
import PayByCardPay from '../../Components/CheckoutComponent/PayByCardPay';

import ConfirmCheckout from '../../Components/CheckoutComponent/ConfirmCheckout';

const tapCurrencies = ['SAR', 'BHD', 'EGP', 'EUR', 'GBP', 'KWD', 'OMR', 'QAR']
const payByCurrencies = ['AED']

function CheckoutPage() {
  const { userId, userToken, userEmail, userName, countryCode, cartToken, languageCode, guestShippment, guestPayment, isPurchase } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const tapId = new URLSearchParams(location.search).get('tap_id') || null
  const orderId = new URLSearchParams(location.search).get('order_id') || null
  const orderToken = new URLSearchParams(location.search).get('order_token') || null
  const couponCode = new URLSearchParams(location.search).get('coupon') || null
  const userParams = { userId: userId, userToken: userToken, countryCode: countryCode }
  const [getSelectShipmentAddressId, setSelectShipmentAddressId] = React.useState();
  const ShippingInfo = useGetShippingAddressesQuery(userParams, { count: 1 }, { refetchOnMountOrArgChange: true })
  const ShippingData = ShippingInfo?.data?.shipping_addresses
  const selectedShipping = ShippingData?.find((s) => s.id === getSelectShipmentAddressId)
  const { data: userData } = useGetUserDataQuery(userParams, { count: 1 }, { refetchOnMountOrArgChange: true })
  const currency = userData?.contant?.currency
  const { data: cartInfo, isLoading, isFetching, refetch } = useFetchQuery({ coupon: couponCode, cartToken: cartToken, ...userParams }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const cartData = cartInfo?.cart
  const thisToken = cartInfo?.token
  const couponData = cartInfo?.coupon

  const [getTestLoading, setTestLoading] = useState(false);
  const [checkoutData, setCheckoutData] = React.useState({});
  const [getCardCheckoutData, cardCheckoutData] = React.useState({});
  const [isTabbySelected, tabbySelected] = React.useState(false);
  const [isApplePaySelected, applepayselected] = React.useState(false);
  const [isSavedCardSelected, savedcardselected] = React.useState(false);
  const [isPayByCardSelected, paybycardselected] = React.useState(false);
  const [isCardSelected, cardselected] = React.useState(false);
  const [getValidInput, setValidInput] = React.useState(false);

  const [getPayByIframe, setPayByIframe] = React.useState(false);
  const [getIframeMessage, setIframeMessage] = React.useState('');

  function closePayByIframe() {
    setPayByIframe(false)
  }

  // call checkout action
  const [createcheckout, checkoutResult] = useCreateCheckoutMutation()

  useEffect(() => {
    if (checkoutResult?.isSuccess) {
      // dispatch(addCheckoutMethod({ isPurchase: checkoutResult?.data?.purchase_token }))
      setTestLoading(true)

      const responseData = checkoutResult?.data?.data
      const tokenUrl = responseData?.transaction?.url || responseData?.body?.interActionParams?.tokenUrl

      if (checkoutData?.payment_by === 'payby_cards') {
        try {
          PayBy.createIframe({
            id: 'container-checkout-test',
            lang: 'en',
            tokenUrl: tokenUrl,
            error: function (msg) {
              setIframeMessage(msg)
            }
          })
        } catch (error) {
          setIframeMessage(error)
        }
      } else {
        window.location.href = tokenUrl;
      }

      setTestLoading(false)
    }
    if (checkoutResult?.isError) {
      setPayByIframe(false)
      setTestLoading(false)
    }
  }, [checkoutResult, checkoutResult?.isSuccess, getTestLoading])


  const TotalPrice = cartData?.reduce((accumulator, value) => {
    const productPrice = value.prices?.value * value.quantity;
    return accumulator + productPrice
  }, 0);


  // checkout payload
  const userInformation =
    userId && userToken ?
      {
        userId: userId,
        userToken: userToken,
        userEmail: userEmail,
        customer_name: selectedShipping?.name,
        customer_email: userEmail,
        payment_mode: 'user',
        customer_phone: selectedShipping?.phone,
      } :
      {
        customer_name: guestShippment?.name,
        customer_email: guestShippment?.email,
        userEmail: guestShippment?.email,
        payment_mode: 'guest',
        customer_phone: guestShippment?.phone,
      }

  const phoneNumber = userInformation?.customer_phone || '';
  const parsedNumber = parsePhoneNumberFromString(phoneNumber);
  const countryPhoneCode = parsedNumber?.countryCallingCode;
  const countryPhoneNumber = parsedNumber?.nationalNumber;

  const checkoutPayload = {
    shipping_address: guestShippment,
    shipping_address_id: getSelectShipmentAddressId,
    language: languageCode,
    country: countryCode,
    countryCode: countryCode,
    currency: cartData?.[0]?.prices?.currency,
    amount: couponData ? Number(TotalPrice) - Number(couponData?.discount) : Number(TotalPrice),
    shipping_price: Number(userData?.contant?.standard_delivery),
    delivery_days: Number(userData?.contant?.delivery_days),
    products: cartData,
    cartToken: cartToken,
    domainName: Host?.domainName,
    domain: window.location.hostname,
    domainEmail: Host?.email,
    token: thisToken,
    coupon: couponData ? couponData : null,
    payment_by: checkoutData?.payment_by,
    tabby_id: checkoutData?.tabby_id,
    card_id: checkoutData?.card_id,
    customer_id: checkoutData?.customer_id,
    countryPhoneCode: countryPhoneCode,
    countryPhoneNumber: countryPhoneNumber,
    customer: userData?.user?.customer,
    data: getCardCheckoutData?.id ? getCardCheckoutData : { id: checkoutData?.tabby_id },
    ...userInformation,
  }

  // Tap card sdk checkout and checkout button action
  const proceedeToCheckout = async () => {

    if (isCardSelected && getValidInput) {
      setTestLoading(true)
      if (window.CardSDK && typeof window.CardSDK.tokenize === 'function') {
        setTestLoading(true)
        await window.CardSDK.tokenize();
      } else {
        console.error('CardSDK or CardSDK.tokenize() is not available.');
        setTestLoading(false)
      }
    }

    // saved card checkout
    if (isSavedCardSelected && checkoutData?.card_id) {
      createcheckout(checkoutPayload)
    }

    // tap tabby checkout
    if (isTabbySelected && checkoutData?.tabby_id) {
      createcheckout(checkoutPayload)
    }

    // tap tabby checkout
    if (isApplePaySelected) {
      createcheckout(checkoutPayload)
    }

    if (isPayByCardSelected) {
      setPayByIframe(true)
      createcheckout(checkoutPayload)
    }

  };

  // new card checkout
  useEffect(() => {
    if (isCardSelected && (getCardCheckoutData?.id && getCardCheckoutData?.card?.id)) {
      createcheckout(checkoutPayload)
    }
  }, [getCardCheckoutData])


  function checkoutWithTapTabby(data) {
    setCheckoutData(data)
    tabbySelected(data.selected)
    applepayselected(false)
    savedcardselected(false)
    cardselected(false)
    paybycardselected(false)
  }

  function checkoutWithPayByApple(data) {
    setCheckoutData(data)
    applepayselected(data.selected)
    tabbySelected(false)
    savedcardselected(false)
    cardselected(false)
    paybycardselected(false)
  }

  function checkoutWithPayByCard(data) {
    setCheckoutData(data)
    paybycardselected(data.selected)
    applepayselected(false)
    tabbySelected(false)
    savedcardselected(false)
    cardselected(false)
  }

  function checkoutWithSavedCard(data) {
    setCheckoutData(data)
    savedcardselected(data.selected)
    tabbySelected(false)
    applepayselected(false)
    cardselected(false)
    paybycardselected(false)
  }

  function checkoutWithCard(data) {
    setCheckoutData(data)
    cardselected(data.selected)
    savedcardselected(false)
    tabbySelected(false)
    applepayselected(false)
    paybycardselected(false)
  }

  function processingLoading(loading, loading2) {
    if (loading) {
      return true
    } else if (loading2) {
      return true
    } else {
      return false
    }
  }

  const isShipmentAddress =
    userId && userToken ?
      getSelectShipmentAddressId ? false : true :
      guestShippment?.email ? false : true

  return (
    <div className="Purchase-page">
      <div className="container purchase-container">
        <div className="row purchase-section" style={{ position: 'relative' }}>

          {orderId && orderToken &&
            <ConfirmCheckout tapId={tapId} orderId={orderId} orderToken={orderToken} />
          }

          {!orderId && !orderToken &&
            <>
              {processingLoading(getTestLoading, checkoutResult?.isLoading) &&
                <div style={{ position: 'absolute', right: 0, left: 0, top: 0, zIndex: 999, display: 'flex', justifyContent: 'center', height: '100%', background: '#ffffffab' }}>
                  <div style={{ textAlign: 'center' }}>
                    <div style={{ marginBottom: '10px', color: '#000', display: 'flex', alignItems: 'center' }}>
                      Processing please wait!
                    </div>
                    <CircularProgress style={{ width: '2rem', height: '2rem', color: '#fe7f4a' }} />
                  </div>
                </div>
              }

              <div className="col-md-6 p0">
                <div className="row">

                  {!userId && !userToken && <GuestShippingAddress />}
                  {userId && userToken && <ShippingAddress ShippingInfo={ShippingInfo} getSelectShipmentAddressId={getSelectShipmentAddressId} setSelectShipmentAddressId={setSelectShipmentAddressId} />}

                  {cartData?.length > 0 &&
                    <div className="col-md-12 pp-padding">
                      <div className='cp-cinfo-card'>

                        <div style={{ marginBottom: '15px' }}>
                          <span className='alert alert-info alert-text'>
                            {t('select_payment_method')}
                          </span>
                        </div>
                        {payByCurrencies?.includes(currency) &&
                          <TapTabbyCheckout
                            cartInfo={cartInfo}
                            userData={userData}
                            guestShippment={guestShippment}
                            getSelectShipmentAddressId={getSelectShipmentAddressId}
                            checkoutWithTapTabby={checkoutWithTapTabby}
                            isTabbySelected={isTabbySelected}
                            isShipmentAddress={isShipmentAddress}
                          />
                        }

                        {payByCurrencies?.includes(currency) &&
                          <>
                            {window.ApplePaySession &&
                              <PayByApplePay
                                cartInfo={cartInfo}
                                userData={userData}
                                guestShippment={guestShippment}
                                getSelectShipmentAddressId={getSelectShipmentAddressId}
                                checkoutWithPayByApple={checkoutWithPayByApple}
                                isApplePaySelected={isApplePaySelected}
                                isShipmentAddress={isShipmentAddress}
                              />
                            }
                          </>
                        }

                        {payByCurrencies?.includes(currency) &&
                          <PayByCardPay
                            cartInfo={cartInfo}
                            userData={userData}
                            guestShippment={guestShippment}
                            getSelectShipmentAddressId={getSelectShipmentAddressId}
                            checkoutWithPayByCard={checkoutWithPayByCard}
                            isPayByCardSelected={isPayByCardSelected}
                            isShipmentAddress={isShipmentAddress}
                          />
                        }

                        <Modal className="pay-purchase-dialogue" show={getPayByIframe} onHide={closePayByIframe} centered animation={false}>
                          <Modal.Body>
                            <IconButton
                              style={{ cursor: 'pointer', position: 'absolute', right: '5px', top: '5px', zIndex: 111 }}
                              onClick={closePayByIframe}
                            >
                              <CloseIcon className="Header-close-modal-icon" />
                            </IconButton>
                            {checkoutResult?.isLoading &&
                              <div style={{ height: '450px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <CircularProgress style={{ width: '2rem', height: '2rem', color: '#fe7f4a' }} />
                              </div>
                            }

                            {getIframeMessage &&
                              <div className='alert alert-danger' style={{ background: '#fff0', border: 'none', margin: 0, padding: '20px' }}>
                                {getIframeMessage}
                              </div>
                            }
                            {checkoutResult?.isSuccess &&
                              <div style={{ height: '500px' }} id="container-checkout-test"></div>
                            }
                          </Modal.Body>
                        </Modal>

                        {tapCurrencies?.includes(currency) &&
                          <TapCardCheckout
                            cartInfo={cartInfo}
                            userData={userData}
                            isShipmentAddress={isShipmentAddress}
                            checkoutWithSavedCard={checkoutWithSavedCard}
                            checkoutData={checkoutData}
                            isSavedCardSelected={isSavedCardSelected}
                            checkoutWithCard={checkoutWithCard}
                            isCardSelected={isCardSelected}
                            setValidInput={setValidInput}
                            countryPhoneCode={countryPhoneCode}
                            countryPhoneNumber={countryPhoneNumber}
                            customerName={userInformation?.customer_name}
                            cardCheckoutData={cardCheckoutData}
                          />
                        }
                      </div>
                    </div>
                  }
                </div>
              </div>

              <div className="col-md-5 pp-padding">
                <div className="row">
                  <div className="col-md-12 pp-padding">
                    <div className='cp-cinfo-card ' style={{ marginBottom: '10px' }}>

                      <CartItems
                        isLoading={isLoading}
                        isFetching={isFetching}
                        cartData={cartData}
                        userData={userData}
                        checkoutResult={checkoutResult}
                      />

                      <CartIInformation
                        isLoading={isLoading}
                        isFetching={isFetching}
                        cartData={cartData}
                        userData={userData}
                        proceedeToCheckout={proceedeToCheckout}
                        couponData={couponData}
                        getValidInput={getValidInput}
                        isSavedCardSelected={isSavedCardSelected}
                        isApplePaySelected={isApplePaySelected}
                        isPayByCardSelected={isPayByCardSelected}
                        isTabbySelected={isTabbySelected}
                        getSelectShipmentAddressId={getSelectShipmentAddressId}
                        checkoutResult={checkoutResult}
                        isShipmentAddress={isShipmentAddress}
                        getTestLoading={getTestLoading}
                      />

                    </div>
                  </div>
                  <FooterText />
                </div>
              </div>
            </>
          }

        </div>
      </div>
      <br /><br />
    </div>
  );
}

export default CheckoutPage;
