import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const checkoutApi = createApi({
  reducerPath: 'checkoutApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Checkout'],
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({

    createCheckout: builder.mutation({
      query: (data) => {
        return {
          url: `api/v1/checkout`,
          method: 'POST',
          body: data,
          headers: {
            'X-Country-Code': data.countryCode,
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Content-type': 'application/json',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Checkout'],
    }),


    confirmCheckout: builder.query({
      query: (data) => ({
        url: `api/v1/checkout/confirm/${data.order_id}?order_id=${data.order_id}&order_token=${data.order_token}&tid=${data.tid}`,
        method: 'GET',
        headers: {
          'X-Country-Code': data.countryCode,
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },

      }),
      // keepUnusedDataFor: 1,
      providesTags: ['Checkout'],
    }),



  }),

})

export const {
  useConfirmCheckoutQuery,
  useCreateCheckoutMutation,
} = checkoutApi