import React, { useState, useEffect, Fragment } from 'react';
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import 'react-phone-number-input/style.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/joy/Button';

function PayByApplePay({ cartInfo, userData, guestShippment, getSelectShipmentAddressId, checkoutWithPayByApple, isApplePaySelected, isShipmentAddress, purchaseconfrmresult, }) {
  const { userId, userToken, userEmail, userName, languageCode, countryCode, cartToken, isPurchase } = useSelector((state) => state.auth);
  const location = useLocation();
  const { t } = useTranslation()
  const dispatch = useDispatch();


  return (
    <>
      <Button
        disabled={isShipmentAddress}
        className='cp-pm-button'
        variant='plain'
        size='sm'
        onClick={() => checkoutWithPayByApple({
          selected: true,
          payment_by: 'payby_apple'
        })}>
        <div className='cp-pm-button-card'>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <RadioGroup style={{ marginRight: '10px' }}>
              <FormControlLabel
                disabled={isShipmentAddress}
                style={{ margin: 0 }}
                value={true}
                control={<Radio checked={isApplePaySelected} />} />
            </RadioGroup>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-apple" viewBox="0 0 16 16">
                  <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
                  <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
                </svg>
                <span style={{ margin: '0px 5px' }}>Apple Pay</span>
              </span>
            </div>
          </div>

          {/* <div style={{ fontSize: '11px', fontWeight: '500', display: 'flex', alignItems: 'center' }}>
            <span>Continue with Apple</span>
            <ArrowForwardIcon style={{ fontSize: '1rem', margin: '0px 5px' }} />
          </div> */}

        </div>
      </Button>
    </>
  );
}

export default PayByApplePay;
