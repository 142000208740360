import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import "antd/dist/antd.min.css";
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import 'react-phone-number-input/style.css'
import { Tag } from "antd";
import formatPrice from '../formatPrice';
import { useDeleteMutation } from '../../services/cart';
import Loader from '../Loader';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/joy/Button';
import CircularProgress from '@mui/material/CircularProgress';
var moment = require('moment');

function CartItems({ isLoading, isFetching, cartData, userData, checkoutResult }) {
  const { userId, userToken, userEmail, userName, languageCode, countryCode, cartToken, guestShippment, guestPayment } = useSelector((state) => state.auth);
  const { t } = useTranslation()

  const [deletecart, deleteresult] = useDeleteMutation()

  function getDeliverydays(data) {
    const deliveryDaya = Number(data) + Number(userData?.contant?.delivery_days || 0)
    function getTimeline() {
      if (Number(deliveryDaya) === 1) {
        return 'Tomorrow'
      } else {
        return ''
      }
    }
    if (Number(deliveryDaya) > 0) {
      return <><strong>{getTimeline()}</strong> {moment().add(Number(deliveryDaya), 'days').format('dddd Do MMM')}</>
    } else {
      return moment().add(10, 'days').format('dddd Do MMM YYYY')
    }
  }

  const groupData = cartData?.reduce((acc, obj) => {
    const key = obj.estimated_delivery;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});

  const soteIds = [3, 1]

  function functionIdLink(id, pid, store_id) {
    if (soteIds?.includes(Number(store_id))) {
      return pid
    } else {
      return id
    }
  }

  function productTitle(title) {
    return title?.replace(/[^A-Za-z0-9]/g, ' ')?.replace(/[^\w\s.,]/gi, ' ')?.replace(/ /g, '-')
  }

  function verifyProduct(available) {
    if (!available?.in_stock) {
      return true;
    }
    return available?.diff;
  }

  return (
    <>

      {checkoutResult?.error?.data?.error &&
        <div className='alert alert-danger a-m-style'>
          {checkoutResult?.error?.data?.error}
        </div>
      }

      {!isLoading &&
        <div style={{ paddingBottom: '15px' }}>
          <span className='alert alert-info alert-text'>
            {t('your_order')}
          </span>
        </div>
      }

      {isLoading &&
        <div style={{ textAlign: 'center', padding: '10px 0px 10px 0px' }}>
          <CircularProgress style={{ width: '2rem', height: '2rem', color: '#fe7f4a' }} />
        </div>
      }

      {cartData?.length === 0 &&
        <Button
          component={Link}
          to={`/`}
          variant='soft'
          size={'lg'}
          style={{ fontWeight: 400, fontSize: '1rem', width: '100%' }}>
          <span>Add item to cart</span>
        </Button>
      }

      {groupData && Object?.entries(groupData).map(([group, groupItems]) => (
        <div key={group} style={{ background: '#f1f1f1', padding: '15px', borderRadius: '10px', marginBottom: '15px' }}>
          <div style={{ fontSize: '12px', fontWeight: 600, paddingBottom: '10px' }}>{t('estimated_delivery')}&nbsp;{getDeliverydays(group)}</div>
          <div>
            {groupItems?.map((item, i) =>
              <div className="col-md-12"
                style={{
                  marginBottom: '15px',
                  padding: '10px',
                  border: checkoutResult?.error?.data?.mismatch && verifyProduct(item.availability) ? '0.7px solid tomato' : '0.7px solid #00000026',
                  borderRadius: '10px',
                  backgroundColor: checkoutResult?.error?.data?.mismatch && verifyProduct(item.availability) ? '#ff634714' : '#fcfcfc'
                }} key={i}>
                <div className="cart-page-card">
                  <Link className="img-card" to={`/${languageCode}/product/${item.store_id}/${functionIdLink(item?.product_id, item?.pid, item?.store_id)}/${productTitle(item.title)}`}>
                    <img
                      style={{ minHeight: '80px', maxHeight: '80px', minWidth: '80px', maxWidth: '80px' }}
                      className="img-item"
                      src={item.image}
                      loading="lazy"
                    />
                  </Link>
                  <div className="cart-page-text" style={{ margin: '0 10px', width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                      <div className="brand" style={{ fontSize: '10px' }}>By {item.brand}</div>
                    </div>
                    <Link to={`/${languageCode}/product/${item.store_id}/${functionIdLink(item?.product_id, item?.pid, item?.store_id)}/${productTitle(item.title)}`}>
                      <div className="title ellipsis-title-2" style={{ fontSize: '12px', color: '#030303' }}>{item.title?.replace(/[^\w\s.,']/gi, ' ')}</div>
                    </Link>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div className="price" style={{ fontSize: '14px', padding: 0, fontWeight: 600 }}>
                        {formatPrice(item.prices?.value, item.prices?.currency, 0)}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        {item.color && <div style={{ fontSize: '10px', margin: '0px 10px' }}>color. &nbsp;{item.color}</div>}
                        <div style={{ fontSize: '10px', margin: '0px 10px' }}>Qty. &nbsp;{item.quantity}</div>
                      </div>
                    </div>

                    <Button
                      disabled={deleteresult?.isLoading && deleteresult?.originalArgs?.id === item.id}
                      onClick={(() => deletecart({
                        id: item.id,
                        cartToken: cartToken,
                        countryCode: countryCode,
                        userId: userId,
                      }))}
                      style={{ position: 'absolute', right: 0, top: 0, zIndex: 111, minHeight: '22px', maxHeight: '22px', borderRadius: '4px' }}
                      variant='plain'
                      size='sm'
                      color='danger'
                    >
                      {deleteresult?.isLoading && deleteresult?.originalArgs?.id === item.id ?
                        <Loader /> :
                        <CloseIcon style={{ fontSize: '14px' }} />
                      }
                    </Button>

                    {checkoutResult?.error?.data?.mismatch &&
                      <>
                        <>
                          {!item.availability?.in_stock &&
                            <small style={{ color: 'tomato', fontSize: '14px', fontWeight: 400 }}>Out of stock</small>
                          }
                          {item.availability?.is_redirect &&
                            <Button
                              component={Link}
                              to={`/product/${item.store_id}/${item.r_pid}`}
                              variant='outlined'
                              color='primary'
                              size='sm'
                              style={{ minHeight: '20px', maxHeight: '20px', margin: '0px 10px' }}>
                              <small>View Product</small>
                            </Button>
                          }
                        </>
                      </>
                    }
                    {item.current_variant &&
                      <div style={{ padding: '5px 0px 5px 0px' }}>
                        {Object.entries(item.current_variant || []).map(([key, value], index) => (
                          <Tag style={{ fontSize: '10px', lineHeight: 'normal' }} key={index}><strong>{key}</strong> {value}</Tag>
                        ))}
                      </div>
                    }
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
}

export default CartItems;
