import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import ProductCarouselSkeleton from '../../Components/ProductCarouselSkeleton';
import { addProductImg } from "../../Reducers/authReducer";
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import { makeStyles } from '@material-ui/core/styles';
import GradeIcon from '@mui/icons-material/Grade';
import { useGetUserDataQuery } from '../../services/login';
import formatPrice from '../formatPrice';
import IconButton from '@mui/joy/IconButton';
import ProductCountries from '../Header/ProductCountries';

var moment = require('moment');
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  item: {
    scrollSnapAlign: 'start',
  },
  imageList: {
    "&::-webkit-scrollbar": {
      display: "none"
    },
    scrollSnapType: 'x mandatory',
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));

function HomeProductCards({ productData, heading, isLoading }) {
  const { t } = useTranslation()
  const { userId, userToken, userEmail, languageCode, countryCode, cartToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const classes = useStyles();
  const { data: userData } = useGetUserDataQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })

  function productTitle(title) {
    return title?.replace(/[^A-Za-z0-9]/g, ' ')?.replace(/[^\w\s]/gi, ' ')?.replace(/ /g, '-')
  }

  function addProductImage(data) {
    dispatch(addProductImg({ productImg: data }));
  }

  let adcPosition
  if (document.body.dir === 'rtl') {
    adcPosition = { left: '10px' }
  } else {
    adcPosition = { right: '10px' }
  }

  const handleError = (e) => {
    console.error('Error loading image:', e.target.src);
    e.target.src = 'https://cdn.ebtida.com/1705315901965-Image-not-found.jpg';
  };

  function getDeliverydays(delivery) {
    const deliveryDaya = Number(delivery) + Number(userData?.contant?.delivery_days || 0)
    function getTimeline() {
      if (Number(deliveryDaya) === 1) {
        return <>{t('delivery_by')} <strong>{t('tomorrow')}</strong> {moment().add(Number(deliveryDaya), 'days').format('Do')}</>
      } else {
        return <>{t('delivery_by')} {moment().add(Number(deliveryDaya), 'days').format('Do MMM')}</>
      }
    }

    if (Number(deliveryDaya) > 0) {
      return getTimeline()
    } else {
      return `${t('delivery_by')} ${moment().add(10, 'days').format('Do MMM')}`
    }
  }

  const soteIds = [3, 1]

  function functionIdLink(id, pid, store_id) {
    if (soteIds?.includes(Number(store_id))) {
      return pid
    } else {
      return id
    }
  }


  const listRef = React.useRef(null);

  const handleScroll = (direction) => {
    if (listRef.current) {
      const itemWidth = listRef.current.scrollWidth / productData?.length;
      listRef.current.scrollBy({
        left: direction === 'next' ? itemWidth * 6 : -itemWidth * 6,
        behavior: 'auto',
      });
    }
  };

  return (
    <>
      {productData?.length > 0 &&
        <div className="cardTitle">{heading}</div>
      }
      {isLoading ?
        <ProductCarouselSkeleton />
        :
        <div style={{ position: 'relative' }}>
          {productData?.length > 6 &&
            <>
              <div className='scroll-back_cards'>
                <IconButton
                  style={{ height: '45px', display: 'flex', alignItems: 'center', background: '#fcfcfc' }}
                  variant='outlined'
                  size='sm'
                  onClick={() => handleScroll('prev')}
                  disabled={false}>
                  <svg width="20" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
                  </svg>
                </IconButton>
              </div>
              <div className='scroll-next_cards'>
                <IconButton
                  style={{ height: '45px', display: 'flex', alignItems: 'center', background: '#fcfcfc' }}
                  variant='outlined'
                  size='sm'
                  onClick={() => handleScroll('next')}
                  disabled={false}>
                  <svg width="20" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                  </svg>
                </IconButton>
              </div>
            </>
          }
          <ImageList style={{ position: 'relative' }} ref={listRef} className={classes.imageList} cols={6}>

            {productData?.map((item, i) =>
              <ImageListItem className="cardItem" key={i}>
                <Link
                  onClick={(() => addProductImage(item?.image))}
                  to={`/product/${item?.store_id}/${functionIdLink(item?.id, item?.pid, item?.store_id)}/${productTitle(item?.title)}`}
                  className="cardItem-item">
                  <div className='img-card imgCard'>
                    <img
                      className="img-item"
                      src={item?.image}
                      alt={item?.title}
                      onError={handleError}
                    />
                    <div className='delivery-days'
                      style={{ position: 'absolute', right: '0px', bottom: 0, color: '#000', fontSize: '12px', lineHeight: 1, padding: '1px 5px 1px 5px', borderRadius: '2px', width: 'auto' }}>
                      <small><i>{getDeliverydays(item.estimated_delivery)}</i></small>
                    </div>
                  </div>
                  <div className="card-content">
                    <div className='title-card'>
                      <h4>{item?.title?.replace(/[^\w\s.,'/:]/gi, ' ')}</h4>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        {item?.brand && <div className='brand'>{item?.brand}</div>}
                        {item?.rating && Number(item?.rating) > 0 &&
                          <div className='rating' style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ display: 'flex', alignItems: 'center', color: '#a8a8a8', margin: '0px 3px', fontSize: '14px' }}>{item?.rating}</span>
                            <GradeIcon style={{ color: '#fe7f4a', fontSize: '1rem', marginTop: '-1.5px' }} />
                          </div>
                        }
                      </div>
                    </div>
                    <div className='content-item'>
                      <h5>
                        {formatPrice(item?.prices?.value, item?.prices?.currency, 0)}
                        {item?.prices?.deal_value &&
                          <small style={{ color: '#fe7f4a', margin: '0px 5px' }}>
                            <small style={{ textDecoration: 'line-through', }}>{item?.prices?.price}</small> 50% OFF
                          </small>
                        }
                      </h5>
                    </div>
                    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <div style={{ fontSize: '12px', lineHeight: 0, margin: '0 5px', color: '#444' }}>
                        {t('from')}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          style={{ width: '20px', objectFit: 'contain' }}
                          loading="lazy"
                          src={`https://flagcdn.com/w20/${item?.country?.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${item?.country?.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        <div style={{ fontSize: '12px', lineHeight: 0, margin: '0 5px', color: '#444' }}>
                          {ProductCountries(item?.country)}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </ImageListItem>
            )}
          </ImageList>
        </div>
      }
    </>
  );
}

export default HomeProductCards
