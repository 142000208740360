import React, { useState, useEffect, Fragment } from 'react';
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import 'react-phone-number-input/style.css'
import Button from '@mui/joy/Button';
import { useGetAllTapPaymentsQuery } from '../../services/payments';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { TapCard, Currencies, Direction, Edges, Locale, Theme } from '@tap-payments/card-sdk'

function TapCardCheckout({ cartInfo, userData, isShipmentAddress, checkoutWithSavedCard, checkoutData, isSavedCardSelected, checkoutWithCard, isCardSelected, cardCheckoutData, setValidInput, countryPhoneCode, countryPhoneNumber, customerName }) {
  const { userId, userToken, userEmail, userName, languageCode, countryCode, cartToken, isPurchase } = useSelector((state) => state.auth);
  const location = useLocation();
  const { t } = useTranslation()
  const dispatch = useDispatch();

  const tapPublicKey = window.location.hostname === 'www.thwifty.ae' ? process.env.REACT_APP_TAP_PUBLIC_KEY_AE : process.env.REACT_APP_TAP_PUBLIC_KEY

  const { data: paymentData, isLoading: paymentLoading, isFetching: paymentFetching } = useGetAllTapPaymentsQuery({ customer: userData?.user?.customer, userId: userId, userToken: userToken, countryCode: countryCode, domain: window.location.hostname }, { count: 1 }, { refetchOnMountOrArgChange: true })

  const cartData = cartInfo?.cart
  const thisToken = cartInfo?.token
  const couponData = cartInfo?.coupon
  const cartCurrencyCode = cartData?.[0]?.prices?.currency
  const standardDelivery = userData?.contant?.standard_delivery

  const TotalPrice = cartData?.reduce((accumulator, value) => {
    const productPrice = value.prices?.value * value.quantity;
    return accumulator + productPrice
  }, 0);

  const isExpired = (expiryDate) => {
    if (!expiryDate) return false;
    const [month, year] = expiryDate.split('/').map(num => parseInt(num, 10));
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear() % 100;
    return year < currentYear || (year === currentYear && month < currentMonth);
  };

  const [isCardReady, setOnReady] = useState(false);

  async function processCardCheckout(data) {
    cardCheckoutData(data)
    console.log('getCardCheckoutData-0', data)
  }

  return (
    <>
      {cartData?.length > 0 &&
        <>

          {paymentLoading &&
            <div style={{ padding: '0px', marginBottom: '10px' }}>
              <Skeleton variant="rect" height={14} width={'80%'} style={{ borderRadius: '4px' }} />
              <Skeleton variant="rect" height={14} width={'80%'} style={{ borderRadius: '4px' }} />
            </div>
          }

          {paymentData?.payments?.length > 0 &&
            <div style={{ marginBottom: '15px', marginTop: '15px' }}>
              <div style={{ fontWeight: 400, marginBottom: '2px', fontSize: '14px' }}>Saved Cards</div>
              {paymentData?.payments?.map((item, i) =>
                <Button
                  disabled={isShipmentAddress || isExpired(`${item.exp_month}}/${item.exp_year}`)}
                  onClick={() => checkoutWithSavedCard({
                    selected: true,
                    payment_by: 'tab_saved_card',
                    card_id: item.id,
                    customer_id: item.customer
                  })}
                  className='cp-pm-button'
                  variant='plain'
                  size='sm'
                  key={i}>
                  <div className='cp-pm-button-card'>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      <RadioGroup style={{ paddingRight: '10px' }}>
                        <FormControlLabel
                          disabled={isShipmentAddress || isExpired(`${item.exp_month}}/${item.exp_year}`)}
                          style={{ margin: 0 }}
                          control={<Radio checked={isSavedCardSelected && checkoutData?.card_id == item.id} />} />
                      </RadioGroup>
                      <div style={{ lineHeight: 1.1, display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ display: 'block' }}>
                          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2px' }}>
                            <span style={{ display: 'flex' }}>
                              <svg width="14" height="14" fill="currentColor" className="bi bi-credit-card" viewBox="0 0 16 16">
                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z" />
                                <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
                              </svg>
                            </span>
                            <span style={{ margin: '0px 5px', fontSize: '12px' }}>{item.brand}</span>
                            <small style={{ color: 'tomato' }}>{isExpired(`${item.exp_month}}/${item.exp_year}`)}</small>
                          </div>
                          <div style={{ fontSize: '13px' }}> Ending with <strong>{item.last_four}</strong> Expiry <strong>{item.exp_month}/{item.exp_year}</strong></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Button>
              )}
            </div>
          }

          <div
            onClick={() => !isShipmentAddress && checkoutWithCard({
              selected: true,
              payment_by: 'tap_card',
            })}
            style={{ background: '#fcfcfc', padding: '10px', border: '1px solid #dddddd', borderRadius: 8, fontSize: '14px', display: 'block', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <RadioGroup style={{ paddingRight: '10px' }}>
                <FormControlLabel
                  disabled={isShipmentAddress}
                  style={{ margin: 0 }}
                  control={<Radio checked={isCardSelected} />} />
              </RadioGroup>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ display: 'flex' }}>
                    <svg width="17" height="17" fill="currentColor" className="bi bi-credit-card" viewBox="0 0 16 16">
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z" />
                      <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
                    </svg>
                  </span>
                  <span style={{ margin: '0px 5px', display: 'flex', alignItems: 'center', fontSize: '1rem' }}>
                    {paymentData?.payments?.length > 0 ? 'New Card' : 'Card'}
                  </span>
                </div>
              </div>
            </div>

            {/* {isCardSelected && */}
            <div className='checkout-t-section' style={{ marginTop: '10px' }}>
              {!isCardReady &&
                <div style={{ padding: '5px' }}>
                  <Skeleton variant="rect" height={14} width={'80%'} style={{ borderRadius: '4px' }} />
                  <Skeleton variant="rect" height={14} width={'80%'} style={{ borderRadius: '4px' }} />
                </div>
              }

              <div style={{ padding: '0px' }}>

                <TapCard
                  publicKey={tapPublicKey}
                  merchant={{
                    id: '41624891'
                  }}
                  transaction={{
                    amount: couponData ? Number(TotalPrice) - Number(couponData?.discount) + Number(standardDelivery) : TotalPrice + Number(standardDelivery),
                    currency: cartCurrencyCode === 'AED' ? Currencies.AED : cartCurrencyCode === 'SAR' ? Currencies.SAR : cartCurrencyCode === 'BHD' ? Currencies.BHD : cartCurrencyCode === 'EGP' ? Currencies.EGP : cartCurrencyCode === 'EUR' ? Currencies.EUR : cartCurrencyCode === 'GBP' ? Currencies.GBP : cartCurrencyCode === 'KWD' ? Currencies.KWD : cartCurrencyCode === 'OMR' ? Currencies.OMR : cartCurrencyCode === 'QAR' ? Currencies.QAR : 'USD'
                  }}
                  customer={{
                    name: [
                      {
                        lang: Locale.EN,
                        first: customerName,
                      }
                    ],
                    editable: true,
                    contact: {
                      email: userEmail,
                      phone: {
                        countryCode: countryPhoneCode,
                        number: countryPhoneNumber
                      }
                    }
                  }}
                  acceptance={{
                    supportedBrands: ['AMEX', 'VISA', 'MASTERCARD'],
                    supportedCards: ['ALL']
                  }}
                  fields={{
                    cardHolder: true
                  }}
                  addons={{
                    displayPaymentBrands: true,
                    loader: true,
                    saveCard: true
                  }}
                  interface={{
                    locale: Locale.EN,
                    theme: Theme.LIGHT,
                    edges: Edges.CURVED,
                    direction: Direction.LTR
                  }}
                  onValidInput={(data) => setValidInput(data)}
                  onReady={() => setOnReady(true)}
                  onError={(data) => setTapCardError(data)}
                  onSuccess={((data) => processCardCheckout(data))}
                  onFocus={() => checkoutWithCard({
                    selected: true,
                    payment_by: 'tap_card',
                  })}
                />

              </div>

            </div>
            {/* } */}

          </div>
        </>
      }



    </>
  );
}

export default TapCardCheckout;
