import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useConfirmCheckoutQuery } from '../../services/checkout';
import CircularProgress from '@mui/material/CircularProgress';
import { useFetchQuery } from '../../services/cart';

function ConfirmCheckout({ tapId, orderId, orderToken }) {
  const { userId, userToken, userEmail, userName, countryCode, cartToken, languageCode, guestShippment, guestPayment, isPurchase } = useSelector((state) => state.auth);
  const history = useHistory();
  const { refetch } = useFetchQuery({ userId: userId, cartToken: cartToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const userParams = { userId: userId, userToken: userToken, countryCode: countryCode }
  const { data: confirmdata, isLoading, isFetching, isSuccess } = useConfirmCheckoutQuery({
    order_id: orderId,
    order_token: orderToken,
    tid: tapId,
    ...userParams
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const results = confirmdata?.results

  useEffect(() => {
    if (isSuccess) {
      // dispatch(addCheckoutMethod({ isPurchase: cartToken }))
      refetch()
      let ItemsData = []
      results?.order_items?.map((item) => {
        ItemsData.push({
          item_id: item?.product_id,
          item_name: item?.title,
          price: item?.price,
          quantity: item?.quantity
        })
      })
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          transaction_id: results?.id,
          currency: results?.currency,
          value: Number(results?.amount),
          shipping: Number(results?.shipping_price),
          items: ItemsData
        }
      });
      history.push(`/purchase/success?order_id=${confirmdata?.order_id}&order_token=${confirmdata?.order_token}`)
    }
  }, [confirmdata, isSuccess])

  return (
    <>
      {isLoading &&
        <div style={{ position: 'absolute', right: 0, left: 0, top: 0, zIndex: 999, display: 'flex', justifyContent: 'center', height: '100%', background: '#ffffffab' }}>
          <div style={{ textAlign: 'center' }}>
            <div style={{ marginBottom: '10px', color: '#000', display: 'flex', alignItems: 'center' }}>
              Processing please wait!
            </div>
            <CircularProgress style={{ width: '2rem', height: '2rem', color: '#fe7f4a' }} />
          </div>
        </div>
      }
    </>
  );
}

export default ConfirmCheckout;
