import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next'
import { useForm } from "react-hook-form";
import Button from '@mui/joy/Button';
import { useSelector, useDispatch } from "react-redux";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import GuestShippingAddressModal from "./GuestShippingAddressModal";

function GuestShippingAddress() {
  const { userId, userToken, userEmail, userName, languageCode, countryCode, cartToken, guestShippment, guestPayment, isPurchase } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { t } = useTranslation()

  const [value, setValue] = React.useState(false);
  const [isModal, setModal] = React.useState(false);
  function openShippingAddressModal() {
    setModal(true)
  }
  function closeShippingAddressModal() {
    setModal(false)
  }

  return (
    <>
      <GuestShippingAddressModal isModal={isModal} closeShippingAddressModal={closeShippingAddressModal} />


      <div className="col-md-12 pp-padding">
        <div className='cp-cinfo-card' style={{ marginBottom: '15px', position: 'relative' }}>

          <div className='cp-guest-mode'>
            <small className='badge badge-info' style={{ fontSize: '10px', background: '#fe7f4a' }}>Guest Mode</small>
          </div>

          <div style={{ marginBottom: '15px', marginTop: '5px' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <div className='alert alert-info alert-text' style={{ marginRight: '10px' }}>
                {t('select_shipping_address')}
              </div>
              <Button
                size='sm'
                variant='outlined'
                style={{ fontWeight: 400, minHeight: '27px', maxHeight: '27px', color: '#fe7f4a', border: '1px solid #fe7f4a' }}
                onClick={(() => openShippingAddressModal())}>
                {guestShippment ?
                  <small>{t('Edit')}</small> :
                  <small>{t('Add')}</small>
                }
              </Button>
            </div>

            {!guestShippment && !guestShippment?.name &&
              <small className="alert alert-danger" style={{ padding: 0, margin: 0, background: '#fff0', border: 'none' }}>
                Add shipping address
              </small>
            }
          </div>


          {guestShippment && guestShippment?.name &&
            <>
              <div className="col-md-12" style={{ marginBottom: '15px', padding: '0px', position: 'relative' }}>
                <div className="cp-shipping-card">
                  <RadioGroup value={value} style={{ paddingRight: '10px' }}>
                    <FormControlLabel
                      style={{ margin: 0 }}
                      value={true}
                      control={<Radio checked={guestShippment} />} />
                  </RadioGroup>
                  <div className="cart-page-text">
                    <div style={{ width: '100%', fontSize: '14px' }}>
                      {/* <div>{guestShippment?.name}</div> */}
                      <div>{guestShippment?.address}, {guestShippment?.street}, {guestShippment?.city}, {guestShippment?.country_code}</div>
                      {/* <div>{guestShippment?.email}, {guestShippment?.phone}</div> */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    </>
  );
}

export default GuestShippingAddress;
