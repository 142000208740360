import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next'
import Host from "../Host";
import { useForm } from "react-hook-form";
import { Modal } from 'react-bootstrap'
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from "react-redux";
import { addGuestShippment } from "../../Reducers/authReducer";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import Countries from '../Header/Countries';

function GuestShippingAddressModal({ isModal, closeShippingAddressModal }) {
  const { userId, userToken, userEmail, userName, languageCode, countryCode, cartToken, guestShippment, guestPayment, isPurchase } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { t } = useTranslation()
  const findCountry = Countries?.find((l) => l.code === countryCode)

  const [getPhone, setPhone] = useState('')
  const [getPhoneError, setPhoneError] = useState(false)

  async function createshippingaddress(data) {
    if (isValidPhoneNumber(getPhone)) {
      dispatch(addGuestShippment({
        guestShippment: {
          name: data.name,
          email: data.email,
          address: data.address,
          street: data.street,
          city: data.city,
          country: findCountry?.label,
          country_code: findCountry?.code,
          zip_code: data.zip_code,
          phone: getPhone
        }
      }));
      closeShippingAddressModal()
    } else {
      setPhoneError(true)
    }
  }

  return (
    <Modal className="shipping-address-modal" show={isModal} onHide={closeShippingAddressModal} centered animation={false}>
      <Modal.Header>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ fontWeight: 500, fontSize: '1.1rem' }}>
            Add new shipping address
          </div>
          <IconButton
            variant='outlined'
            size='sm'
            color='danger'
            style={{ minHeight: '27px', maxHeight: '27px' }}
            onClick={closeShippingAddressModal}
          >
            <CloseIcon className="Header-close-modal-icon" style={{ fontSize: '1.2rem' }} />
          </IconButton>
        </div>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(createshippingaddress)}>
          <div className="row">
            <div className="col-md-5">
              <div className="form-group">
                <label style={{ fontSize: '14px' }}>{t('name')} </label>
                <input
                  defaultValue={guestShippment?.name}
                  type="text"
                  className="form-control"
                  id="name"
                  aria-invalid={errors.name ? "true" : "false"}
                  {...register('name', { required: true })} />
              </div>
            </div>
            <div className="col-md-7">
              <div className="form-group">
                <label style={{ fontSize: '14px' }}>{t('email')} </label>
                <input
                  defaultValue={guestShippment?.email}
                  type="text"
                  className="form-control"
                  id="email"
                  aria-invalid={errors.email ? "true" : "false"}
                  {...register('email', { required: true })} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label style={{ fontSize: '14px' }}>{t('address_flat')} </label>
                <input
                  defaultValue={guestShippment?.address}
                  type="text"
                  className="form-control"
                  id="address"
                  aria-invalid={errors.address ? "true" : "false"}
                  {...register('address', { required: true })} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label style={{ fontSize: '14px' }}>{t('street_area')} </label>
                <input
                  defaultValue={guestShippment?.name}
                  type="text"
                  className="form-control"
                  id="street"
                  aria-invalid={errors.street ? "true" : "false"}
                  {...register('street', { required: true })} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label style={{ fontSize: '14px' }}>{t('city')} </label>
                <input
                  defaultValue={guestShippment?.city}
                  type="text"
                  className="form-control"
                  id="city"
                  aria-invalid={errors.city ? "true" : "false"}
                  {...register('city', { required: true })} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label style={{ fontSize: '14px' }}>{t('country')} </label>
                <select className="form-control">
                  <option disabled selected>{findCountry?.label}</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label style={{ fontSize: '14px' }}>{t('phone')} </label>
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry={countryCode}
                  value={getPhone}
                  onChange={setPhone}
                />
                {getPhoneError &&
                  <>
                    {getPhone && isValidPhoneNumber(getPhone) ? '' :
                      <div><small style={{ color: 'tomato' }}>{'Invalid phone number'}</small></div>
                    }
                  </>
                }
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label style={{ fontSize: '14px' }}>{t('zip_code')} </label>
                <input
                  defaultValue={guestShippment?.zip_code}
                  type="number"
                  className="form-control"
                  id="zip_code"
                  aria-invalid={errors.zip_code ? "true" : "false"}
                  {...register('zip_code', { required: false })} />
              </div>
            </div>
            <div className="col-md-12 Header-signin-button">
              <div className="form-group">
                <Button
                  style={{ background: Host?.bgColor, color: Host?.buttonColor, width: '100%', height: '45px' }}
                  type="submit">
                  {t('submit')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default GuestShippingAddressModal;
