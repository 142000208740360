import React, { useState, useEffect, Fragment } from 'react';
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/joy/Button';

function TapTabbyCheckout({ cartInfo, userData, guestShippment, getSelectShipmentAddressId, checkoutWithTapTabby, isTabbySelected, isShipmentAddress }) {
  const { userId, userToken, userEmail, userName, languageCode, countryCode, cartToken, isPurchase } = useSelector((state) => state.auth);
  const location = useLocation();
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const cartData = cartInfo?.cart
  const thisToken = cartInfo?.token
  const couponData = cartInfo?.coupon

  const TotalPrice = cartData?.reduce((accumulator, value) => {
    const productPrice = value.prices?.value * value.quantity;
    return accumulator + productPrice
  }, 0);

  function formatPriceTabby(price, currency, shipping) {
    const numericPrice = Number(price) + Number(shipping);
    const tabbySplitPrice = Number(numericPrice) / 4
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency ? currency : 'AED',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })?.format(tabbySplitPrice);
  };

  function getThisLoading(loading1, loading2) {
    if (loading1) {
      return true
    } else if (loading2) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <Button
        disabled={isShipmentAddress}
        className='cp-pm-button'
        variant='plain'
        size='sm'
        onClick={(() => checkoutWithTapTabby({
          selected: true,
          payment_by: 'tap_tabby',
          tabby_id: 'src_tabby.installement'
        }))}
      >
        <div className='cp-pm-button-card'>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <RadioGroup style={{ marginRight: '10px' }}>
              <FormControlLabel
                disabled={isShipmentAddress}
                style={{ margin: 0 }}
                value={true}
                control={<Radio checked={isTabbySelected} />} />
            </RadioGroup>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ lineHeight: 1.1 }}>
                <div style={{ display: 'block' }}>
                  <div style={{ paddingBottom: '2px' }}><img src='/tabby-logo-1.png' style={{ width: '50px' }} /></div>
                  <small>Split in 4 payments of&nbsp;
                    {formatPriceTabby(couponData ? Number(TotalPrice) - Number(couponData?.discount) : TotalPrice, cartData?.[0]?.prices?.currency, userData?.contant?.standard_delivery)}
                    . No interest</small>
                </div>
              </div>
            </div>
          </div>
          {/* <div style={{ fontSize: '11px', fontWeight: '500', display: 'flex', alignItems: 'center' }}>
            <span style={{ margin: '0px 5px' }}>Continue with Tabby</span>
            <ArrowForwardIcon style={{ fontSize: '1rem' }} />
          </div> */}
        </div>
      </Button>
    </>
  );
}

export default TapTabbyCheckout;
