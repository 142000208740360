import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import Host from "../Host";

function FooterText() {
  const { t } = useTranslation()
  return (
    <div className="col-md-12 pp-padding">
      <div style={{ padding: '20px', background: '#fcfcfc', border: '0.7px solid #00000014', borderRadius: '10px' }}>
        <div>
          <small style={{ fontSize: '12px' }}><strong>Security and Privacy</strong></small>
        </div>
        <div style={{ lineHeight: 1 }}>
          <small style={{ fontSize: '12px', lineHeight: 1 }}>Our checkout is safe and secure. We do not store any payment card information on our systems</small>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Link style={{ fontSize: '12px', margin: '0 5px' }} to="/privacy-policy">{t('privacy_policy')}</Link>
          <Link style={{ fontSize: '12px', margin: '0 5px' }} to="/terms-and-conditions">{t('terms_and_conditions')}</Link>
        </div>
        <div style={{ textAlign: 'center' }}>
          <small style={{ fontSize: '12px' }}>© 2024 {Host?.domainName}. All Rights Reserved</small>
        </div>
      </div>
    </div>
  );
}

export default FooterText;
