import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useSelector } from "react-redux";
import { useConfirmCheckoutQuery } from '../../services/checkout';
import { useTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Tag } from "antd";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useFetchQuery } from '../../services/cart';
import Button from '@mui/joy/Button';
var moment = require('moment');

function CheckoutConfirm() {
  const { userId, userToken, userEmail, userName, countryCode, cartToken, languageCode, guestShippment, guestPayment, isPurchase } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const { refetch } = useFetchQuery({ userId: userId, cartToken: cartToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })

  const tapId = new URLSearchParams(location.search).get('tap_id') || null
  const orderId = new URLSearchParams(location.search).get('order_id') || null
  const orderToken = new URLSearchParams(location.search).get('order_token') || null
  const userParams = { userId: userId, userToken: userToken, countryCode: countryCode }

  const { data: confirmdata, isLoading, isFetching, isSuccess } = useConfirmCheckoutQuery({
    order_id: orderId,
    order_token: orderToken,
    tid: tapId,
    ...userParams
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const results = confirmdata?.results


  useEffect(() => {
    if (isSuccess) {
      refetch()
      let ItemsData = []
      results?.order_items?.map((item) => {
        ItemsData.push({
          item_id: item?.product_id,
          item_name: item?.title,
          price: item?.price,
          quantity: item?.quantity
        })
      })
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          transaction_id: results?.id,
          currency: results?.currency,
          value: Number(results?.amount),
          shipping: Number(results?.shipping_price),
          items: ItemsData
        }
      });
    }
  }, [confirmdata, isSuccess])


  function getDeliverydays(data, created) {
    function getTimeline() {
      if (Number(data) === 1) {
        return 'Tomorrow'
      } else {
        return ''
      }
    }
    if (Number(data) > 0) {
      return <><strong>{getTimeline()}</strong> {moment(created).add(Number(data), 'days').format('dddd Do MMM YYYY')}</>
    } else {
      return moment(created).add(10, 'days').format('dddd Do MMM YYYY')
    }
  }

  const groupData = results?.order_items?.reduce((acc, obj) => {
    const key = obj.estimated_delivery;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});

  const soteIds = [3, 1]
  function functionIdLink(id, pid, store_id) {
    if (soteIds?.includes(Number(store_id))) {
      return pid
    } else {
      return id
    }
  }
  function productTitle(title) {
    return title?.replace(/[^A-Za-z0-9]/g, ' ')?.replace(/[^\w\s.,]/gi, ' ')?.replace(/ /g, '-')
  }

  function fetchLoadingAndFetching(loading, fetching) {
    if (loading) {
      return true
    } else if (fetching) {
      return true
    } else {
      return false
    }
  }



  function getitesmData(data) {
    return (
      <div className='sp-section'>
        <div className='sp-card' style={{ background: '#fff0', padding: '20px' }}>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
            <div style={{ textAlign: 'center' }}>
              <CheckCircleIcon color='success' style={{ fontSize: '2rem', marginBottom: '10px' }} />
              <div>
                Your order <strong>#{results?.order_number}</strong> has been placed successfully. <br />Thank you
              </div>
            </div>
          </div>

          {userId && userToken &&
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
              <Button
                style={{ fontWeight: 500 }}
                component={Link}
                to={`/account/orders`}
                variant='soft'
                size={'sm'}>
                View your order
              </Button>
            </div>
          }

          <div className='sp-heading'>{t('your_order')}</div>
          <div className='sp-card-header'>
            <div style={{ fontSize: '14px' }}>
              <div>Order Number: &nbsp;{results?.order_number}</div>
              <div>Total Items: &nbsp;{results?.order_items?.length}</div>
            </div>
            <div style={{ fontSize: '14px' }}>
              <div>Order Status: &nbsp;{results?.status ?
                <span style={{ color: 'green' }}>Placed</span> :
                <span style={{ color: 'tomato' }}>Pending</span>}</div>
              <div>{t('order_placed_on')} {moment(data?.createdAt).format("MMM Do YYYY")}</div>
            </div>
          </div>

          {groupData && Object?.entries(groupData).map(([group, groupItems]) => (
            <div
              key={group}
              style={{
                border: '1px solid #eee',
                borderRadius: '10px',
                padding: '15px',
                background: '#f0f4f8',
                marginBottom: '15px'
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: '10px' }}>

                <div style={{ fontSize: '14px', fontWeight: 500 }}>
                  Delivery by&nbsp;{getDeliverydays(group, data?.createdAt)}
                </div>

                <div style={{ fontSize: '14px', fontWeight: 500 }}>Items: {groupItems?.length}</div>
              </div>

              <div className='sp-item-section'>
                {groupItems?.map((items, index) =>
                  <div key={index}>
                    <div className='sp-item-card' style={{ background: '#fff', padding: '12px' }}>
                      <Link to={`/product/${items.store_id}/${functionIdLink(items?.product_id, items?.pid, items?.store_id)}/${productTitle(items.title)}`}>
                        <img className='sp-item-img' src={items.image} />
                      </Link>
                      <div className='sp-item-title'>
                        <Link to={`/product/${items.store_id}/${functionIdLink(items?.product_id, items?.pid, items?.store_id)}/${productTitle(items.title)}`}>
                          <p style={{ margin: 0 }}>{items.title?.replace(/[^\w\s.,/:']/gi, ' ')}</p>
                        </Link>
                        <div className='sp-item-content' style={{ padding: '5px 0px 0px 0px' }}>
                          <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px', flexWrap: 'wrap' }}>
                            <Tag>Condition: {items.conditions}</Tag>
                            <Tag>Qty.: {items.quantity}</Tag>
                          </div>
                        </div>
                        {items.current_variant &&
                          <div style={{ padding: '5px 0px 0px 0px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                            {Object.entries(items.current_variant || []).map(([key, value], index) => (
                              <Tag style={{ fontSize: '10px' }} key={index}><strong>{key}:</strong> {value}</Tag>
                            ))}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className="Purchase-page-Success Account-page">
      <br /><br />
      <div className="container purchase-container">
        <div className="row purchase-section">
          {fetchLoadingAndFetching(isLoading, isFetching) &&
            <div className="col-md-12 mp0">
              <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <div style={{ textAlign: 'center' }}>
                  <div style={{ fontSize: '1.2rem', marginBottom: '10px' }}>
                    Processing please wait!
                  </div>
                  <CircularProgress style={{ width: '2rem', height: '2rem', color: '#fe7f4a' }} />
                </div>
              </div>
            </div>
          }
          {!fetchLoadingAndFetching(isLoading, isFetching) &&
            <div className="col-md-7 mp0">
              <div className="row" style={{ justifyContent: 'center', width: '100%' }}>
                <div className="col-md-12 mp0">
                  <>
                    {getitesmData(results?.order_items)}
                  </>
                </div>
              </div>
            </div>
          }
        </div>
      </div >
      <br /><br />
    </div >
  );
}

export default CheckoutConfirm;
